import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
    
    body {
        margin: 0;
    }

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        filter: invert(1);
        opacity: 0.2;
    }
`