import React from 'react';
import styled from 'styled-components'
import { GlobalStyle } from './globalStyle'
import { BackgroundSketch } from './components/BackgroundSketch'

export const App = () => (
    <>
        <GlobalStyle />
        <Background>
            <Title><Link href="https://liamp.uk">coming soon</Link></Title>
        </Background>
        <BackgroundSketch></BackgroundSketch>
    </>
)

const Title = styled.p`
  font-size: 1.5em;
  text-align: center;
  color: #e3dcc8;
  margin: 0;
  font-family: 'Abril Fatface';
  font-size: 60px;
  text-decoration: underline;
  z-index: 5;

  &:hover {
      color: #c4bca3;
      cursor: pointer;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #0a356b;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit;
`