import React from 'react';
import P5 from "p5";

export const BackgroundSketch = () => {
    
    new P5(Sketch)
    
    return (
        <></>
    )
}

const Sketch = (p5 : P5) => {

    let img : P5.Image

    p5.preload = () => {
        img = p5.loadImage('https://i.imgur.com/QDk5aRz.png');
    }

    p5.setup = () => {
        const canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight)
        canvas.parent("app")
        p5.frameRate(1)
    }

    // The sketch draw method
    p5.draw = () => {
        console.log(p5.random(p5.windowWidth) + " " + p5.random(p5.windowHeight))
        p5.image(img, p5.random(p5.windowWidth-55), p5.random(p5.windowHeight-120), 55, 120)
        
    }
}